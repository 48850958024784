@font-face
  font-family: 'Pretendard'
  font-weight: 900
  font-display: swap
  src:  url('../assets/fonts/PretendardJP-Black.otf')

@font-face
  font-family: 'Pretendard'
  font-weight: 800
  font-display: swap
  src: url('../assets/fonts/PretendardJP-ExtraBold.otf')

@font-face
  font-family: 'Pretendard'
  font-weight: 700
  font-display: swap
  src: url('../assets/fonts/PretendardJP-Bold.otf')

@font-face
  font-family: 'Pretendard'
  font-weight: 600
  font-display: swap
  src: url('../assets/fonts/PretendardJP-SemiBold.otf')

@font-face
  font-family: 'Pretendard'
  font-weight: 500
  font-display: swap
  src: url('../assets/fonts/PretendardJP-Medium.otf')

@font-face
  font-family: 'Pretendard'
  font-weight: 400
  font-display: swap
  src: url('../assets/fonts/PretendardJP-Regular.otf')

@font-face
  font-family: 'Pretendard'
  font-weight: 300
  font-display: swap
  src: url('../assets/fonts/PretendardJP-Light.otf')

@font-face
  font-family: 'Pretendard'
  font-weight: 200
  font-display: swap
  src: url('../assets/fonts/PretendardJP-ExtraLight.otf')

@font-face
  font-family: 'Pretendard'
  font-weight: 100
  font-display: swap
  src: url('../assets/fonts/PretendardJP-Thin.otf')
