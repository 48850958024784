$left-side-frame-width : 300px

.LeftSideFrame
  box-sizing: border-box
  width: $left-side-frame-width
  height: 100%
  border-right: 1px solid #DEE2E6
  display: flex
  gap: 20px

.LeftSideContainer
  box-sizing: border-box
  display: flex
  flex-direction: column
  align-items: flex-start
  padding: 16px 16px 16px
  width: 300px
  height: 100%
  flex: none
  order: 0
  align-self: stretch
  flex-grow: 0
  overflow-y: auto

.LeftSideContainer::-webkit-scrollbar
  display: none

.BodyContainer
  display: flex
  width: 100%
  height: calc(100vh - 64px)
  overflow-x: hidden
  overflow-y: hidden

.ContentFrame
  display: flex
  position: relative
  width: 100%
  height: calc(100vh - 64px)
  flex-grow: 1

.ContentTextArea
  width: calc(100% - 24px)
  order: 0
  padding: 32px 36px
  overflow-y: auto

.ContentTextArea::-webkit-scrollbar
  display: none

.BookmarkVerticalFrame
  display: flex
  width: 100%
  height: 100%

.BookmarkVerticalFrameWithBookmarkClose
  width: 0px

.RightSideFrame
  display: flex
  flex-direction: column
  align-items: flex-start
  padding: 0px
  width: 100%
  height: calc(100vh - 64px)
  flex: none
  flex-grow: 1

// .RightSideFrame::-webkit-scrollbar
//   display: none

.BookmarkIconBarVertical
  order: 1

.BookmarkOpenFloatingButtonFrame
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  padding: 0px
  gap: 2px
  position: absolute
  width: 44px
  right: 19.81px
  top: 20px
  flex: none
  flex-grow: 0
  z-index: 3
  cursor: pointer

.BookmarkOpenFloatingButton
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  padding: 0px
  width: 44px
  height: 44px
  background: #E7F5FF
  border-radius: 999px

.BookmarkOpenFloatingText
  font-weight: 700
  font-size: 11px
  line-height: 13px
  text-align: center
  color: #228BE6


.BookmarkAreaArrowIcon
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  padding: 0px
  width: 24px
  height: 44px
  top: 0px
  background: #F1F3F5
  border-radius: 4px 0px 0px 4px
  cursor: pointer

.BookmarkHorizontalFrame
  width: 100%
  height: 100%
  display: flex
  flex-direction: row
  z-index: 5
  position: absolute
  pointer-events: none

.BookmarkHorizontalTopAreaFrame
  width: 100%
  opacity: 0

.BookmarkHorizontalBottomAreaFrame
  pointer-events: all
  background-color: white
  width: 100%

.divider
  pointer-events: all

