@use 'components/lnb/lnb'

.TableTitleItem
  display: flex
  flex-direction: row
  align-items: flex-start
  padding: 16px 0px 16px 20px
  gap: 8px
  width: 100%
  height: fit-content
  flex: none
  order: 0
  align-self: stretch
  flex-grow: 0
  flex-wrap: wrap
  cursor: pointer

.TableTitleItemText
  display: flex
  flex-direction: row
  align-items: flex-start
  padding: 16px 0px 16px 20px
  width: 269px
  flex: none
  align-self: stretch
  flex-grow: 0
  flex-wrap: wrap

.TableTitleItemText:hover
  cursor: pointer

.TableTitleId
  font-family: 'Pretendard'
  font-style: normal
  font-weight: 700
  font-size: 15px
  line-height: 18px
  color: #ADB5BD
  flex: none

.TableTitleName
  font-family: 'Pretendard'
  font-style: normal
  font-weight: 500
  font-size: 15px
  line-height: 18px
  color: #343A40
  flex: none

.SelectedTableTitleItem
  font-style: normal
  font-weight: 700
  color: #228BE6


.TableTitleSearchCount
  margin-top: 10px
  margin-right: 10px
  line-height: 40px

.TableTitleNumber
  color: #8a8584

.SelectedTableTitle
  background: #F8F9FA

.TableTitleArea
  width: calc(100vw - 240px)
  display: flex
  flex-direction: column

.TableTitleContentArea
  width: 100%
  flex-grow: 1
  display: flex
  padding: 24px
  gap: 24px

.TableTitleListArea
  display: flex
  flex-direction: row
  align-items: flex-start
  padding: 24px
  gap: 24px

.TableTitleColumnFrame
  display: flex
  flex-direction: column
  align-items: flex-start
  padding: 0px
  width: 270px
  height: 630px
  flex: none
  flex-grow: 0

.MainBodyFrame
  width: calc(100% - lnb.$lnb-width)
  height: 100vh

.TableTitleSearchResultExist
  background-color: #F1F3F5
