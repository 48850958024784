.StandardListItemFrame
  display: flex
  flex-direction: column
  align-items: flex-start
  padding: 0px 20px
  width: 240px
  height: fit-content
  flex: none
  order: 0
  flex-grow: 0

.StandardListItem
  display: flex
  flex: none
  flex-grow: 0
  flex-direction: row
  flex-wrap: wrap
  align-items: flex-start
  padding: 14px 4px 14px 12px
  width: 200px
  height: fit-content
  border-radius: 2px
  order: 0
  gap: 4px 0px

.StandardListItemTextFrame
  display: flex
  flex-direction: row
  align-items: center
  padding: 0px
  gap: 2px
  height: 19px
  flex: none
  order: 0
  flex-grow: 0

.StandardListItemText
  align-items: center
  font-family: 'Pretendard'
  font-style: normal
  font-weight: 400
  font-size: 16px
  line-height: 19px
  color: #495057
  flex: none
  order: 0
  flex-grow: 0

.SelectedStandardListItemFrame
  display: flex
  flex-direction: row
  align-items: stretch
  padding: 0px 20px 0px 0px
  gap: 16px
  width: 240px
  height: fit-content
  flex: none
  order: 1
  flex-grow: 0

.SelectedLeftSideBar
  width: 4px
  background: #228BE6
  flex: none
  flex-grow: 0

.SelectedStandardListItem
  display: flex
  flex-direction: row
  align-items: flex-start
  padding: 14px 4px 14px 12px
  width: 220px
  height: fit-content
  background: #F8F9FA
  flex: none
  flex-grow: 0
  flex-wrap: wrap
  gap: 4px 0px

.SelectedStandardListItemText
  font-weight: 700
  color: #212529
