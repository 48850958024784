$header_height: 64px

.TableTitleHeaderArea
  box-sizing: border-box
  display: flex
  flex-direction: row
  flex-shrink: 0
  align-items: center
  padding: 0px 24px
  width: 100%
  height: $header_height
  border-bottom: 1px solid #E9ECEF

.TableTitleHeaderIdText
  height: 19px
  font-family: 'Pretendard'
  font-style: normal
  font-weight: 700
  font-size: 16px
  line-height: 19px
  color: #ADB5BD

.TableTitleHeaderText
  height: 19px
  font-family: 'Pretendard'
  font-style: normal
  font-weight: 700
  font-size: 16px
  line-height: 19px
  color: #000000
  flex: none
  flex-grow: 0
  margin-left: 8px

.TableTitleHeaderIcon
  width: 24px
  height: 24px
  margin-left: 10px
  display: flex
  flex-direction: column
  justify-content: center
