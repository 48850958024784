.SearchResultFrame
  display: flex
  flex-direction: row
  gap: 8px
  padding: 0px 20px
  width: 240px
  margin-top: 12px
  flex-wrap: wrap

.SearchKeywordItem
  box-sizing: border-box
  display: flex
  flex-direction: row
  gap: 8px
  align-items: center
  padding: 6px 8px
  width: fit-content
  background: #F8F9FA
  border-bottom: 1px solid
  border-radius: 2px 2px 0px 0px
  font-weight: 700
  font-size: 13px
  line-height: 16px
  color: #343A40

.SearchKeywordItemText
  width: calc(100% - 20px)
  order: 0

.SearchKeywordItemDelete
  width: 20px
  height: 20px
  flex: none
  order: 1
  flex-grow: 0
  cursor: pointer

.SearchKeywordBold
  font-family: 'Pretendard'
  font-style: normal
  font-weight: 700
  font-size: 13px
  color: #343A40

.SearchKeywordNormal
  font-family: 'Pretendard'
  font-style: normal
  font-weight: 400
  font-size: 13px
  color: #495057

.SearchCountItem
  height: 18px
  border-radius: 999px
  color: #212529
  padding: 0px 6px
  font-weight: 700
  font-size: 12px
  line-height: 20px
  margin-left: 4px

.MaxSearchAlertFrame
  display: flex

.MaxSearchAlertText
  color: #F03E3E
  font-weight: 400
  font-size: 12px
  line-height: 130%

.TitleSearchCountItem
  height: 18px
  border-radius: 999px
  color: #868E96
  background-color: #E9ECEF
  padding: 0px 6px
  font-weight: 700
  font-size: 12px
  line-height: 18px
  margin-left: 4px
