@use 'styles'

$bookmark_resize_handle_width: 12px
$bookmark_header_frame_height: 44px

.BookmarkNumberList
  margin-right: 10px
  border: styles.$border
  display: flex
  font-size: 15px
  padding-left: 5px
  padding-right: 5px
  height: 25px
  line-height: 25px

.SelectedBookmarkNumberList
  color: styles.$text-blue-color
  border: styles.$border

.BookmarkNumberTextItem
  margin-right: 20px
  cursor: pointer

.BookmarkNumberRemoveItem
  width: 20px
  text-align: right
  color: styles.$text-gray-color
  cursor: pointer

.BookmarkNumberRemoveItem:hover
  color: styles.$text-red-color
  font-weight: bold

.BookmarkDescription
  width: 100%
  height: 200px
  margin-top: 20px
  p
    display: block
    margin: 0

.BookmarkContent
  width: 100%
  padding: 0px 24px 0px 24px
  height: calc(100% - $bookmark_header_frame_height * 2)
  overflow-y: auto
  scroll-behavior: smooth
  user-select: text
  position: relative

.BookmarkContent::-webkit-scrollbar
  display: none

.Bookmark > * > *
  margin-top: 10px !important

.BookmarkHeader
  display: flex

.BookmarkHeaderTextItem
  margin-right: 20px
  cursor: pointer

.BookmarkHeaderRemoveItem
  width: 20px
  text-align: right
  color: styles.$text-gray-color
  cursor: pointer

.BookmarkHeaderRemoveItem:hover
  color: styles.$text-red-color
  font-weight: bold

.BookmarkHeaderItem
  border: styles.$border
  margin: 0px 10px 10px 0px
  height: 35px
  line-height: 35px
  display: flex
  font-size: 15px
  padding-left: 5px
  padding-right: 5px

.SelectedBookmarkHeaderItem
  color: styles.$text-blue-color
  border: styles.$border

.BookmarkContentHeader
  display: flex

.BookmarkHeaderFrame
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  padding: 22px
  width: 100%
  height: $bookmark_header_frame_height
  background: #F8F9FA
  flex: none
  order: 0
  align-self: stretch
  flex-grow: 0

.BookmarkHeaderIconGroupFrame
  display: flex
  flex-direction: row
  align-items: center
  padding: 0px
  gap: 4px
  width: 52px
  height: 24px
  flex: none
  order: 0
  flex-grow: 0

.BookmarkHeaderEditTextFrame
  height: 17px
  font-family: 'Pretendard'
  font-style: normal
  font-weight: 500
  font-size: 14px
  line-height: 17px
  flex: none
  display: flex
  gap: 16px
  order: 1
  flex-grow: 0
  cursor: pointer

.BookmarkHeaderEditTextDisabled
  color: #CED4DA

.BookmarkHeaderEditTextEnabled
  color: #228BE6

.BookmarkHeaderButton
  cursor: pointer

.BookmarkHeaderCloseButton
  color: #868E96

.BookmarkTabFrame
  display: flex
  flex-direction: row
  align-items: flex-start
  padding: 16px 20px 0px
  gap: 16px
  width: 100%
  height: $bookmark_header_frame_height
  border-bottom: 1px solid #DEE2E6
  align-self: stretch
  cursor: pointer
  overflow: auto
  white-space: nowrap

.BookmarkTabFrame::-webkit-scrollbar
  display: none

.BookmarkTabStandardName
  height: 100%
  font-weight: 500
  font-size: 13px
  line-height: 16px
  color: #ADB5BD

.BookmarkTabStandardNameSelected
  font-weight: 700
  color: #228BE6
  border-bottom: 2px solid #228BE6

.VerticalBookmarkFrame
  position: relative
  top: 0
  left: 0
  display: flex
  height: 100%
  width: 100%

.HorizontalBookmarkFrame
  width: 100%
  height: 100%

.VerticalBookmarkResizeHandle
  box-sizing: border-box
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  padding: 20px 0px
  width: $bookmark_resize_handle_width
  height: 100%
  background: #E9ECEF
  border-left: 1px solid #DEE2E6
  flex: none
  order: 0
  align-self: stretch
  flex-grow: 0

.HorizontalBookmarkResizeHandle
  box-sizing: border-box
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  height: 12px
  width: 100%
  background: #E9ECEF
  border-left: 1px solid #DEE2E6
  flex: none
  order: 0
  align-self: stretch
  flex-grow: 0

.VerticalBookmarkResizeHandleIcon
  width: 20px
  height: 50px
  flex: none
  order: 0
  flex-grow: 0

.VerticalBookmarkArea
  width: calc(100% - $bookmark_resize_handle_width)
  height: 100%

.HorizontalBookmarkArea
  height: calc(100% - $bookmark_resize_handle_width)
  width: 100%

.BookmarkAreaVerticalIcon
  display: flex
  align-items: center
  cursor: pointer

.BookmarkAreaHorizontalIcon
  display: flex
  align-items: center
  cursor: pointer

.BookmarkNoContent
  display: flex
  flex-direction: row
  justify-content: center
  align-items: center
  padding: 10px
  gap: 10px
  width: 100%
  height: 100%
  flex: none
  order: 1
  align-self: stretch
  flex-grow: 1

.BookmarkNoContentText
  width: 89px
  height: 40px
  font-family: 'Pretendard'
  font-style: normal
  font-weight: 400
  font-size: 14px
  line-height: 140%
  text-align: center
  color: #ADB5BD
  flex: none
  order: 0
  flex-grow: 0
  word-break: keep-all

.BookmarkDeleteIcon
  width: 20px
  height: 20px
  cursor: pointer

.BookmarkOneDepthNameFrame
  display: flex
  gap: 4px
  margin-top: 20px

.BookmarkOneDepthName
  font-weight: 600
  font-size: 13px

.BookmarkContentFrame
  padding: 16px 0px
  border-bottom: 1px solid #DEE2E6

.BookmarkContentNameFrame
  display: flex
  gap: 4px

.BookmarkContentName
  font-weight: 700
  font-size: 12px
  color: #ADB5BD
  margin-bottom: 4px

.BookmarkContentText
  font-weight: 400
  font-size: 12px
  color: #343A40

.BookmarkDuplicateAddAlertFrame
  display: flex
  flex-direction: row
  justify-content: space-between
  align-items: center
  padding: 12px 16px
  gap: 8px
  position: absolute
  width: 320px
  height: 48px
  left: 560px
  top: 24px
  background-color: #FFF4E6
  border-radius: 8px

.BookmarkAlertText
  display: flex
  flex-direction: row
  align-items: center
  padding: 0px
  gap: 8px
  height: 20px
  flex: none
  flex-grow: 0
  color: #FD7E14
  font-weight: 700
  font-size: 16px
  line-height: 19px

.BookmarkAlertIcon
  width: 24px
  height: 24px
  padding: 2px

.BookmarkShowTable
  display: flex
  flex-direction: row
  align-items: center
  padding: 0
  margin: 0
  svg
    width: 20px
    height: 20px
    padding: 2.5px
    margin-right: 2px
  span
    color: #228BE6
    font-family: 'Pretendard'
    font-weight: 700
    font-size: 12px
    line-height: 150%

.BookmarkTableModal
  display: flex
  align-items: center
  justify-content: center
  .excel_table_div
    margin-top: 40px
    padding: 0px
    overflow-x: unset
  .ant-modal-close-x
    width: fit-content
    height: fit-content


.BookmarkCloseTable
  display: flex
  flex-direction: row
  align-items: center
  padding: 0
  margin: 24px
  svg
    width: 24px
    height: 24px
    padding: 2px
  span
    color: #343A40
    font-family: 'Pretendard'
    font-weight: 400
    font-size: 15px
    line-height: 150%
