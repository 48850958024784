@use 'styles/variables'

.normal_d0
  margin-left: 0

.normal_d1
  margin-left: 20px
  margin-bottom: 0
  box-decoration-break: clone

.normal_d2
  margin-left: 40px
  margin-bottom: 0
  box-decoration-break: clone

.small_d0, .small_d1
  font-size: 12px

.fraction
  width: max-content
  tr:first-of-type
    border-bottom: solid black 1px
  td
    text-align: center

.bookmark
  color: variables.$text-blue-color
  font-weight: 700
  cursor: pointer

.bookmark_other_standard
  color: variables.$text-indigo-color
  font-weight: 700
  cursor: pointer

.npo_name, .as_name, .as_subtitle
  font-weight: 700

.npo_d3
  margin-left: 20px
  margin-bottom: 0
  box-decoration-break: clone

.npo_d4
  margin-left: 40px
  margin-bottom: 0
  box-decoration-break: clone

.npo_cell
  height: 20px

.kifrs_case_name
  font-weight: 800
  font-size: 15px

.picas_table
  border: 1px solid #444444
  border-collapse: collapse
  th, td
    border: 1px solid #444444

.excel_div
  display: flex
  flex-direction: column
  width: auto
  overflow-x: auto
  padding: 10px
  // background: #ebebeb
  table
    background: white

.excel_table_type
  @extend .excel_div

.excel_example_type
  @extend .excel_div
  tr:first-child > td:first-child
    padding: 8px
    font-weight: 700

.excel_attention_type
  @extend .excel_div
  tr:first-child > td:first-child
    padding: 8px
    font-weight: 700
    color: black
    background: variables.$background-grey-color
  td
    padding: 0 8px

.excel_table_title
  text-align: center
  font-size: 13px
  margin-top: 5px

.excel_number_cell
  text-align: right
  padding-right: 4px


@for $i from 1 through 10
  .p_d#{$i}
    margin-left: calc(#{$i * 20}px - 20px)
    -webkit-box-decoration-break: clone

.p_footnote
  font-size: 13px

.p_ref
  width: auto
  margin: 10px
  border: 1px solid variables.$border-grey-color
  p:first-child
    background: variables.$background-grey-color
    padding: 8px
    font-weight: 700
    border-bottom: 1px solid variables.$border-grey-color
    margin-bottom: 8px
  p
    padding: 0 8px
  p:not(:first-child)
    margin-left: -20px
