$lnb-width: 260px

.Lnb
  left: 0px
  right: 0px
  width: $lnb-width
  height: 100vh
  border-right: 1px solid #DEE2E6
  display: flex
  flex-direction: column

.LnbTopFrame
  display: flex
  flex-direction: column
  align-items: center
  padding: 0px 0px 60px
  gap: 24px
  width: 240px
  min-height: 360px

.LnbTopLogoFrame
  display: flex
  flex-direction: column
  align-items: flex-start
  padding: 20px
  width: 240px
  height: 88px
  flex: none
  order: 0
  flex-grow: 0

.LnbLogo
  display: flex
  flex-direction: row
  align-items: center
  padding: 0 12px
  gap: 4px
  width: 200px
  height: 48px
  flex: none
  order: 0
  flex-grow: 0

.LnbLogoText
  font-family: 'Pretendard'
  font-style: normal
  font-weight: 700
  font-size: 20px
  color: black

.LnbStandardListFrame
  display: flex
  flex-direction: column
  align-items: flex-start
  padding: 0px
  width: 240px
  flex: none
  order: 1
  flex-grow: 0

.LnbBanner
  flex: 1
  display: flex
  align-items: end
  justify-content: center
  margin: 50px 20px 0 20px
  a
    width: 100%
    padding: 10px
    border-top: 1px solid #DEE2E6
    text-align: center
    font-family: 'Pretendard'
    font-style: normal
    font-weight: 700
    font-size: 16px
